import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import useStore from "../../store/useStore";
import { useLoading } from "../../context/loading";
import repository from "../../core/api";
import "./questionnaire.scss";

const MobileQuestionnairePage: React.FC = () => {
  const { form, answers, addAnswer, updateQuestionnaires, questionnaires, setCurrentForm, clearAnswer, reset } = useStore();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState<{
    [key: string]: string;
  }>({});
  const navigate = useNavigate();
  const { setLoading } = useLoading();
  const questionnaire = form.questionnaire;

  const handleAnswer = (questionId: string, answerId: string) => {
    addAnswer(questionId, answerId);
    setSelectedAnswers({ ...selectedAnswers, [questionId]: answerId });
  };

  const handleNext = async () => {
    try {
      if (currentQuestionIndex < (questionnaire.length || 0) - 1) {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
      } else {
        setLoading(true);
        const formId = form.id;
        const answer_payload = Object.keys(answers).map((key: string) => ({ question_id: key, answer_id: answers[key] }))
        await repository.put(`/assessment-answer/${formId}`, answer_payload);
        const newBook: any[] = [...questionnaires].slice(1);
        updateQuestionnaires(newBook);
        setLoading(false);
        if (newBook.length > 0) {
          navigate("/onbording", { replace: true });
          setTimeout(() => {
            clearAnswer();
            setCurrentForm(newBook[0] ?? null);
          }, 200);
        } else {
          navigate("/completion", { replace: true });
          setTimeout(() => {
            reset();
          }, 200);
        }
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handlePrev = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const currentQuestion = questionnaire[currentQuestionIndex];
  const currentStep = currentQuestionIndex + 1;
  return (
    <div className="questionnaire">
      <div className="flex items-center mb-4">
        <i
          className="fas fa-chevron-left text-xl mr-4"
          onClick={handlePrev}
        ></i>
        <div className="relative w-full h-1 bg-gray-200 rounded-full">
          <div
            className="absolute top-0 left-0 h-full bg-blue-500 rounded-full"
            style={{
              width:
                (
                  (currentQuestionIndex * 100) /
                  questionnaire.length
                ).toString() + "%",
            }}
          ></div>
        </div>
      </div>
      <div className="text-left mb-2">
        <div className="question-step">
          <span className="current">
            {currentStep > 9 ? currentStep : `0${currentStep}`}
          </span>
          <span> / {questionnaire.length}</span>
        </div>
        <p className="question-text mt-2">{currentQuestion.text}</p>
      </div>
      <div className="question-subtext">{currentQuestion.subtext}</div>
      <div className="space-y-4 flex-1">
        {currentQuestion.options.map((option: any) => (
          <label
            key={option.id}
            className={`flex items-center justify-between option cursor-pointer ${selectedAnswers[currentQuestion.id] === option.id
                ? "selected-option"
                : ""
              }`}
          >
            <span>{option.text}</span>
            <input
              key={option.text}
              type="radio"
              name={currentQuestion.id}
              value={option.id}
              checked={selectedAnswers[currentQuestion.id] === option.id}
              onClick={() => handleAnswer(currentQuestion.id, option.id)}
              onChange={() => { }}
              className="form-radio"
            />
          </label>
        ))}
      </div>
      <button
        className="button"
        onClick={handleNext}
        disabled={!Boolean(selectedAnswers[currentQuestion.id])}
      >
        Confirm
      </button>
    </div>
  );
};

export default MobileQuestionnairePage;
