import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

interface QuestionnaireState {
  userInfo: any | null;
  schoolInfo: any | null;
  welcomeInfo: any | null;
  form: any | null;
  answers: Record<string, string>;
  questionnaires: any[];
  setUserInfo: (data: any) => void;
  setSchoolInfo: (data: any) => void;
  setWelcomeInfo: (data: any) => void;
  updateQuestionnaires: (data: any[]) => void;
  setCurrentForm: (form: any) => void;
  addAnswer: (question: string, answer: string) => void;
  clearAnswer: () => void;
  reset: () => void;
}

const useStore = create(
  persist<QuestionnaireState>(
    (set) => ({
      userInfo: null,
      schoolInfo: null,
      welcomeInfo: null,
      form: null,
      answers: {},
      questionnaires: [],
      setUserInfo: (data) => set({ userInfo: data }),
      setSchoolInfo: (data) => set({ schoolInfo: data }),
      setWelcomeInfo: (data) => set({ welcomeInfo: data }),
      setCurrentForm: (form) => set({ form }),
      updateQuestionnaires: (data) => set({ questionnaires: data }),
      addAnswer: (question, answer) => 
        set((state) => ({
          answers: {...state.answers, [question] : answer },
        })),
      clearAnswer: () => set({ answers: {} }),
      reset: () => set({
        welcomeInfo: null,
        form: null,
        answers: {},
        questionnaires: [],
      }),
    }),
    {
      name: "assessment-storage",
      storage: createJSONStorage(() => localStorage),
    }
  )
);

export default useStore;
