import React from "react";
import * as images from "../../assets/images";
import useStore from "../../store/useStore";
import "./completion.scss";

const MobileCompletionPage: React.FC = () => {
  React.useEffect(() => {
    localStorage.removeItem("assessment-storage");
    useStore.persist.clearStorage();
  });
  return (
    <div className="completion">
      <div className="relative inline-block">
        <img
          src={images.congrats}
          alt="Smiling face icon"
          className="h-185 mx-auto"
        />
      </div>
      <h1 className="title">Assessment Completed</h1>
      <p className="description">
        You're all done! If there's anything we need to talk about, the school
        will reach out to you. You can go ahead and close this window now.
      </p>
    </div>
  );
};

export default MobileCompletionPage;
