import React from "react";
import "./assessment-onbording.scss";
import DesktopAssessmentOnbordingPage from "./Desktop";
import MobileAssessmentOnbordingPage from "./Mobile";

const AssessmentOnbordingPage: React.FC = () => {
  return (
    <>
      <div className="desktop-view">
        <DesktopAssessmentOnbordingPage />
      </div>
      <div className="mobile-view">
        <MobileAssessmentOnbordingPage />
      </div>
    </>
  );
};

export default AssessmentOnbordingPage;
