import React from "react";
import DesktopCompletionPage from "./desktop";
import MobileCompletionPage from "./mobile";
import "./completion.scss";

const CompletionPage: React.FC = () => {
  return (
    <>
      <div className="desktop-view">
        <DesktopCompletionPage />
      </div>
      <div className="mobile-view">
        <MobileCompletionPage />
      </div>
    </>
  );
};

export default CompletionPage;
