import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import * as images from "../../assets/images";
import repository from "../../core/api";
import useStore from "../../store/useStore";
import "./splash.scss";

const SplashScreen: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("token");
  const { setUserInfo, setSchoolInfo, setWelcomeInfo, updateQuestionnaires, setCurrentForm } = useStore();

  async function fetchData() {
    try {
      const response = await repository
        .post(`/assessment-answer/forms/`, { token: id })
        .then((res) => res.data);
      const forms = response.forms.filter((element: any) => !(element?.is_completed ?? true));
      if (forms.length > 0) {
        setWelcomeInfo(response.welcome);
        setUserInfo(response.user);
        setSchoolInfo(response.school);
        updateQuestionnaires(forms);
        setCurrentForm(forms[0]);
        navigate("/welcome", { replace: true });
      } else {
        navigate("/completion", { replace: true });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  useEffect(() => {
    fetchData();
  }, [id]);

  return (
    <div className="splash-screen h-screen flex items-center justify-center text-white">
      <img
        src={images.VectorBGF}
        alt="Background"
        className="desktop-bg absolute inset-0 w-full h-full object-cover"
      />
      <img
        src={images.VectorBG}
        alt="Background"
        className="mobile-bg absolute inset-0 w-full h-full object-cover"
      />
      <div className="relative z-10 text-center">
        <div className="loader mx-auto"></div>
      </div>
    </div>
  );
};

export default SplashScreen;
