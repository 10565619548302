import React from "react";
import * as images from "./../../assets/images";
import useStore from "../../store/useStore";
import "./completion.scss";

const DesktopCompletionPage: React.FC = () => {
  const { userInfo:user, clearAnswer } = useStore();
  React.useEffect(() => {
    clearAnswer();
  }, [clearAnswer]);
  return (
    <div className="flex h-screen">
      <div className="w-1/2 relative bg-blue-900 flex flex-col justify-center items-center text-white p-8">
        <img
          src={images.VectorBGF}
          alt="Background"
          className="absolute inset-0 object-cover h-full"
        />
        <img
          src={images.logo}
          alt="logo-the-anxiety-center"
          style={{ zIndex: "1", position: "absolute", left: 92, top: 72 }}
        />
        <h1 className="user-name">Thank you {user.name}!</h1>
      </div>
      <div className="w-1/2 bg-white flex flex-col justify-center items-center p-8">
        <div className="flex flex-col items-center">
          <img src={images.logoColor} alt="school logo" className="mb-4" style={{ height: 100 }} />
          <p
            className="text-gray-700 text-center mb-4"
            style={{ maxWidth: 340 }}
          >
            You're all done! If there's anything we need to talk about, the
            school will reach out to you. You can go ahead and close this window
            now.
          </p>
        </div>
      </div>
    </div>
  );
};

export default DesktopCompletionPage;
