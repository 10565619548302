import React from "react";
import "./welcome.scss";
import DesktopWelcomePage from "./Desktop";
import MobileWelcomePage from "./Mobile";

const WelcomePage: React.FC = () => {
  return (
    <>
      <div className="desktop-view">
        <DesktopWelcomePage />
      </div>
      <div className="mobile-view">
        <MobileWelcomePage />
      </div>
    </>
  );
};

export default WelcomePage;
