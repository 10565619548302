import React from "react";
import { useNavigate } from "react-router-dom";
import useStore from "../../store/useStore";
import "./assessment-onbording.scss";

const MobileAssessmentOnbordingPage: React.FC = () => {
  const navigate = useNavigate();
  const { form } = useStore();
  const content = form.onboarding_content;
  function start() {
    navigate("/questionnaire");
  }
  return (
    <div className="assessment-onbording max-w-md mx-auto bg-white p-6">
      <div className="title">{form.name}</div>
      {form && form.description && (<div className="description">{form.description}</div>)}
      {content.map(({ title, description }: any, index: number) => {
        return (
          <div className="content" key={`dec_${index}`}>
            <h2 className="title">{title}</h2>
            <div className="description" dangerouslySetInnerHTML={{ __html: description }} />
          </div>
        );
      })}
      <button className="button" onClick={start}>Confirm</button>
    </div>
  );
};

export default MobileAssessmentOnbordingPage;
