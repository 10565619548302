import React from "react";
import { useNavigate } from "react-router-dom";
import * as images from "../../assets/images";
import useStore from "../../store/useStore";
import "./welcome.scss";

const DesktopWelcomePage: React.FC = () => {
  const navigate = useNavigate();
  const { userInfo: user, welcomeInfo } = useStore();
  return (
    <div className="flex h-screen">
      <div className="w-1/2 relative bg-blue-900 flex flex-col justify-center items-center text-white p-8">
        <img
          src={images.VectorBGF}
          alt="Background"
          className="absolute inset-0 object-cover h-full"
        />
        <img
          src={images.logo}
          alt="logo-the-anxiety-center"
          style={{ zIndex: "1", position: "absolute", left: 92, top: 72 }}
        />
        <h1 className="user-name">Hi {user.name}!</h1>
      </div>
      <div className="w-1/2 bg-white flex flex-col justify-center items-center p-8">
        <div className="flex flex-col items-start">
          <img src={images.logoColor} alt="school logo" className="mb-4" style={{ height: 100 }} />
          <span className="font-bold">{welcomeInfo?.title}</span>
          {welcomeInfo.description.map((element: string, index: number) => {
            return (<p key={`desc_${index}`} className="text-gray-700 text-center mt-2" style={{ maxWidth: 340, textAlign: "left" }}>{element}</p>)
          })}
          <div className="mb-4" />
          <button className="welcome-button" onClick={() => { navigate("/onbording", { replace: true }); }}>
            Start
          </button>
        </div>
      </div>
    </div>
  );
};

export default DesktopWelcomePage;
