import React from "react";
import DesktopQuestionnairePage from "./Desktop";
import MobileQuestionnairePage from "./Mobile";
import "./questionnaire.scss";

const QuestionnairePage: React.FC = () => {
  return (
    <>
      <div className="desktop-view">
        <DesktopQuestionnairePage />
      </div>
      <div className="mobile-view">
        <MobileQuestionnairePage />
      </div>
    </>
  );
};

export default QuestionnairePage;
