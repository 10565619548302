import React from "react";
import { useNavigate } from "react-router-dom";
import * as images from "../../assets/images";
import useStore from "../../store/useStore";
import "./welcome.scss";

const MobileWelcomePage: React.FC = () => {
  const navigate = useNavigate();
  const { userInfo: user, schoolInfo: school, welcomeInfo } = useStore();
  return (
    <div className="splash-screen flex items-center justify-center text-white">
      <img
        src={images.VectorBG}
        alt="Background"
        className="absolute inset-0 w-full h-full object-cover"
      />
      <div className="relative z-10">
        <div className="welcome flex flex-col items-center justify-center">
          <div className="flex-1 text-white w-full max-w-md p-4">
            <div className="flex flex-col items-center mt-4">
              <img src={school.white_logo_url} alt="School Logo" />
            </div>
          </div>
          <div className="bg-white text-center p-6 rounded-t-3xl w-full max-w-md">
            <h2 className="title">Hi {user?.name}!</h2>
            <span className="font-bold text-black w-full text-left">{welcomeInfo?.title}</span>
            {welcomeInfo.description.map((element: string, index: number) => {
              return (<p key={`desc_${index}`} className="text-gray-700 text-center mt-1" style={{ maxWidth: 340, textAlign: "left" }}>{element}</p>)
            })}
            <div className="mb-4" />
            <button className="button" onClick={() => { navigate("/onbording", { replace: true }); }}>
              Start
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileWelcomePage;
