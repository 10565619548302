import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import WelcomePage from "./pages/welcome/WelcomePage";
import SplashScreen from "./pages/splash/SplashScreen";
import QuestionnairePage from "./pages/questionnaire/QuestionnairePage";
import CompletionPage from "./pages/completion/CompletionPage";
import AssessmentOnbordingPage from "./pages/onbording/AssessmentOnbording";
import LoadingPage from "./components/LoadingPage";
import { LoadingProvider, useLoading } from "./context/loading";

const App: React.FC = () => {
  return (
    <LoadingProvider>
      <Router>
        <LoadingComponent />
        <Routes>
          <Route path="/" element={<SplashScreen />} />
          <Route path="/welcome" element={<WelcomePage />} />
          <Route path="/onbording" element={<AssessmentOnbordingPage />} />
          <Route path="/questionnaire" element={<QuestionnairePage />} />
          <Route path="/completion" element={<CompletionPage />} />
        </Routes>
      </Router>
    </LoadingProvider>
  );
};

const LoadingComponent: React.FC = () => {
  const { loading } = useLoading();
  return loading ? <LoadingPage /> : null;
};

export default App;
