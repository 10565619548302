import React from "react";
import { useNavigate } from "react-router-dom";
import useStore from "../../store/useStore";
import * as images from "./../../assets/images";
import "./assessment-onbording.scss";

const DesktopAssessmentOnbordingPage: React.FC = () => {
  const navigate = useNavigate();
  const { form } = useStore();
  const content = form.onboarding_content;
  function start() {
    navigate("/questionnaire");
  }
  return (
    <div className="assessment-onbording-desktop min-h-screen">
      <img className="bg-image" src={images.VectorBGF} alt="background" />
      <div className="logos">
        <img src={images.logoH} alt="logo" />
        <img src={images.logoColor} alt="school-logo" style={{ height: 100 }} />
      </div>
      <div className="box">
        <div className="box-content">
          <div className="left">
            <h1 className="title">{form.name}</h1>
            {form && form.description && <div className="description">
              {form.description}
            </div>}
            {content.map(({ title, description }: any, index: number) => {
              return (
                <div className="content" key={`dec_${index}`}>
                  <h2 className="title">{title}</h2>
                  <div
                    className="description"
                    dangerouslySetInnerHTML={{ __html: description }}
                  />
                </div>
              );
            })}
          </div>
          <div className="right">
            <button className="button" onClick={start}>
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesktopAssessmentOnbordingPage;
